import React, { FC, useState, useEffect, useMemo } from 'react'
import { Button, Card, Image } from 'semantic-ui-react'
import { useNavigation } from '../../hooks/useNavigation'
import { WindowsAntivirus } from '../App/WindowsAntivirus'
import { License } from '../Antivirus/License'
import { App } from '../../shared/types'

type AntivirusGroup = { key: string; title: string; apps: App[] }
type IconKey = 'android' | 'ios' | 'linux' | 'macos' | 'windows'

const icons = require.context('~assets/images/icons/os', false, /^((?!\/-).)*\.svg$/)
const iconsIndex = icons.keys().reduce((acc, key) => {
  const icon = (key.split(/\//).at(-1) as string).replace(/\.svg$/, '')
  return { ...acc, [icon]: icons(key) }
}, {} as Record<IconKey, string>)

const mobileApps = ['ios', 'android']
const customComponents: Record<string, FC<{ app: App, onUpdate?: (value: string) => void }>> = {
  'windows-antivirus': WindowsAntivirus,
}

export function iconFor(key: string): string {
  if (iconsIndex.hasOwnProperty(key)) {
    return iconsIndex[key as IconKey]
  }

  const fallback = Object.keys(iconsIndex).find(k => key.includes(k)) as IconKey | undefined
  return fallback ? iconsIndex[fallback] : require('~assets/images/icons/shield.svg')
}

const Dashboard: FC<{ data: App[], devices: number }> = ({ data, devices }) => {
  const { setNav, resetNav } = useNavigation()
  const [license, setLicense] = useState<string>()

  const groupedApps = useMemo(() => {
    const result = [
      { key: 'mobile', title: 'Mobile Apps', apps: [] },
      { key: 'desktop', title: 'Desktop Apps', apps: [] },
    ] as AntivirusGroup[]

    data.forEach(app => {
      if (app.key.includes('antivirus')) {
        result[mobileApps.some(v => app.key.includes(v)) ? 0 : 1].apps.push(app)
      }
    })

    return result
  }, [data])

  useEffect(() => {
    setNav('hidden')
    return resetNav
  }, [])

  return (
    <div>
      <License license={license} devices={devices} onUpdate={value => setLicense(value)} />
      {groupedApps.filter(l => l.apps.length).map(group => (
        <div key={group.key} style={{ marginTop: 24 }}>
          <div className="text">{group.title}</div>

          {group.apps.map(app => {
            const Component = customComponents[app.key]

            if (Component) {
              return <Component key={app.id} app={app} onUpdate={value => setLicense(value)} />
            }

            return (
              <Card fluid key={app.id} className="dense">
                <Card.Content>
                  <div className="grid grid-apps-item">
                    <Image src={iconFor(app.key)} height={58} className="mr-1" />
                    <h6>{app.name}</h6>
                    <div className="text--subdued text--small mr-auto">{app.notes}</div>
                    <Button size="big" primary onClick={() => window.open(app.downloadUrl, '_blank')}>
                      Download
                    </Button>
                  </div>
                </Card.Content>
              </Card>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export { Dashboard }
