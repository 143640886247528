/// <reference types="@types/webpack-env" />
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Component, ComponentType } from 'react'
import QS, { ParseOptions } from 'query-string'

// @ts-ignore
import Session from '~shared/storages/session'
// @ts-ignore
import { action, observable, observer } from '@decorators'
import { trackVirtualPageView } from '../utils/tracking'

export type Authorizeable = { authorize?: boolean | (() => boolean | string); auth?: string }
type Route = { matcher: RegExp; component: ComponentType & Authorizeable }
type LoadSettings = { replace?: boolean; state?: Hash }

export const QS_FORMAT: ParseOptions = {
  arrayFormat: 'comma',
  parseNumbers: true,
  parseBooleans: true,
}

export default class Router {
  /* HELPERS */
  static Page: ComponentType

  static setTitle(extra: string | false): void {
    let newTitle = this.defaultTitle
    newTitle += extra ? `- ${extra}` : ''
    window.document.title = newTitle
  }

  /* DEFINITION */
  static routes: Route[] = []

  static defaultTitle = window.document.title

  static addContext(ctx: __WebpackModuleApi.RequireContext, prefix = ''): void {
    ctx.keys().forEach((entry: string) => {
      let matcher = entry.substring(1, entry.length - 4)

      matcher = matcher.replace(/\[(\w+)\]/g, '(?<$1>[^/]+)')
      matcher = matcher.replace(/(?:^(\/)index|\/index)$/, '$1')

      if (prefix) {
        matcher = `/${prefix}${matcher}`
      }

      this.routes.unshift({
        matcher: new RegExp(`^${matcher}$`, 'i'),
        component: ctx(entry).default,
      })
    })
  }

  /* OPERATIONS */
  static notFound: ComponentType = () => <></>

  static unauthorizedDefault = '/'

  static loadedPath = ''

  @observable
  static params: Hash = {}

  @observable
  static page?: ComponentType

  static get locationPath(): string {
    return window.location.pathname
  }

  static get qs(): Hash {
    return QS.parse(window.location.search, QS_FORMAT)
  }

  @action
  static render(component?: Route['component'], params?: Hash, path?: string): boolean {
    const authResult = Router.checkAuthorize(component)

    // Hack to logout user before applying ?token if user already logged in
    if (Session.authenticated && component?.auth && Router.qs.token) {
      Session.clear()
    }

    // Change the locale if a specific one has been provided in the query string
    if (Router.qs['L']) {
      Session.locale = Router.qs['L'] as string
    }

    if (Session.authenticated && component?.auth) {
      Router.redirect(component.auth)
    } else if (!authResult || authResult === path) {
      Router.loadedPath = path || Router.locationPath
      Router.params = params || {}
      Router.page = component
      Router.track()
    } else {
      Router.redirect(typeof(authResult) === 'string' ? authResult : Router.unauthorizedDefault)
    }

    // A component was found, even though it may not be authorized
    return true
  }

  static redirect(path?: string, data?: LoadSettings): void {
    if (path) {
      Router.updateHistory(path, data)
    }

    if (Router.loadedPath === path) {
      return
    }

    const newPath = Router.locationPath
    Router.routes.find(({ matcher, component }) => {
      const result = newPath.match(matcher)
      return result && Router.render(component, result.groups, path || newPath)
    }) || Router.render(this.notFound, {})

    window.scrollTo(0, 0)
  }

  static updateHistory(page: string, data?: LoadSettings): void {
    if (page === ':back' || page === ':forward') {
      window.history[page.substring(1) as 'back' | 'forward']()
    } else {
      const func = data?.replace ? 'replaceState' : 'pushState'
      window.history[func](data?.state || {}, '', page)
    }

    this.track()
  }

  static checkAuthorize(component?: Route['component']): boolean | string {
    if (typeof component === 'undefined') {
      return false
    } else if (typeof component.authorize === 'undefined') {
      return Session.authorize
    } else if (typeof component.authorize === 'boolean') {
      return component.authorize
    } else if (typeof component.authorize === 'function') {
      return component.authorize()
    } else {
      throw new Error(`Invalid type ${typeof component.authorize} for authorize.`)
    }
  }

  static track = (): void => {
    const authId = (Session.authenticated && Session.profile?.id) || null
    trackVirtualPageView(document.location.href, document.title, authId)
  }
}

@observer
export class Page extends Component {
  constructor(props: any) {
    super(props)
    window.onpopstate = this.onChange
    window.addEventListener('click', this.onLink)
    this.onChange()
  }

  onLink = (ev: MouseEvent): void => {
    const baseElement = ev.target as HTMLElement
    const element: HTMLElement = baseElement.closest('a[href]') || baseElement.closest('[data-href]') || baseElement

    const data = element.dataset
    const href = element.getAttribute('href') || data.href

    const hasHref = (element.tagName === 'A' && element.hasAttribute('href')) || data.href
    const validHref = !(href?.startsWith('http') || href?.startsWith('#'))

    if (hasHref && validHref) {
      if (!element.hasAttribute('disabled') || !element.getAttribute('disabled')) {
        Router.redirect(href as string, data)
      }

      ev.stopPropagation()
      ev.preventDefault()
    }
  }

  onChange = (): void => {
    Router.redirect()
  }

  render() {
    return Router.page ? React.createElement(Router.page) : null
  }
}

(Router.notFound as any).auth = '/apps'
Router.Page = Page
