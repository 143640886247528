import { isEnv } from './config'
import { PlanSellingPoint } from './type'

const CardIcon = require('~assets/images/icons/card.svg')
const SupportIcon = require('~assets/images/icons/support.svg')
const MobileIcon = require('~assets/images/icons/mobile.svg')
const VPNIcon = require('~assets/images/icons/vpn.svg')
const UserIcon = require('~assets/images/icons/user.svg')

// eslint-disable-next-line max-len
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const nameRegex = /^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/

export const planSellingPointsDescription: PlanSellingPoint[] = [
  { icon: CardIcon, title: '60 Day Risk Free Guarantee' },
  { icon: SupportIcon, title: '24/7 Support' },
  { icon: MobileIcon, title: 'Unlimited Devices' },
  { icon: VPNIcon, title: 'VPN', subtitle: 'Internet Security' },
  { icon: UserIcon, title: 'Number of Users' },
]

export const PAYPAL_ENV = process.env.PAYPAL_ENV
export const IDENTISAFE_SSO_URL = process.env.IDENTISAFE_SSO_URL
export const BASE_URL = (isEnv('production') && PAYPAL_ENV !== 'staging') ? 'www.paypal.com' : 'www.sandbox.paypal.com'
export const PAYPAL_URL = `https://${BASE_URL}/cgi-bin/webscr?cmd=_express-checkout&token=`
