import React, { FC, useEffect, useRef, useMemo, useState } from 'react'
import { Loader, Form, Image } from 'semantic-ui-react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import Notifications from '../shared/notifications'
import { CardComponent } from '../fragments/payment/CardComponent'
import Router from '../shared/router'
import { useNavigation } from '../hooks/useNavigation'
import { SimpleBox } from '../components/SimpleBox'
import { CardToken } from '../type'
import AddonCard from '../components/Checkout/AddonCard'
import PaymentMethod from '../components/Checkout/PaymentMethod'
import { LOGO_INDEX as CARD_LOGO } from '../fragments/PaymentSourceCell'
import { ChargebeeItemPrice } from '../shared/types'
import { ChargebeeCoupon } from '../utils/coupon'
import { SimpleText } from '../components/SimpleText'
import { calculateCouponDiscount as couponDiscount } from '../graphql/checkout'
import { toMoneyWithoutCalculation as toMoney } from '../shared/format'
import Decimal from 'decimal.js'

const CardOther = require('~assets/images/card-other.svg')
const PaymentCardsImage = require('~assets/images/payment-cards.svg')

type QueryData = {
  account: { customer: { primaryPaymentSource: { type: string; card: any } } }
  promoAddons: {
    addons: Array<Pick<ChargebeeItemPrice, 'id' | 'itemId' | 'price'>>
    features: { title: string; description: string; imageUrl: string; chargebeeId: string }[]
    coupon: ChargebeeCoupon
  }
}
type Feature = QueryData['promoAddons']['features'][0] & QueryData['promoAddons']['addons'][0]

const QUERY = gql`
  {
    account { customer { primaryPaymentSource { type card } } }
    promoAddons {
      addons { id name itemId price }
      features { title description imageUrl chargebeeId  }
      coupon { id name durationType discountType discountPercentage discountAmount }
    }
  }
`

type MutationVariables = { ids: string[], token?: string }
const MUTATION = gql`
  mutation AttachPromoAddons($ids: [ID!]!, $token: String) {
    result: attachPromoAddons(ids: $ids, cardToken: $token)
  }
`

const Promos: FC = () => {
  const cardRef = useRef<any>()
  const totalRef = useRef<Decimal>(new Decimal(0))
  const { setNav, resetNav, setSide, resetSide } = useNavigation()
  const [confirmed, setConfirmed] = useState(false)
  const [cardErrors, setCardErrors] = useState<string[]>([])
  const [showCard, setShowCard] = useState(false)
  const [applyLoading, setApplyLoading] = useState(false)
  const [cardValid, setCardValid] = useState(false)
  const [selectedAddons, setSelectedAddons] = useState<Set<string>>(new Set())
  const { t } = useTranslation()

  const { data, loading } = useQuery<QueryData>(QUERY)
  const [attachPromoAddons] = useMutation<{ result: boolean }, MutationVariables>(MUTATION)

  const features: Feature[] = useMemo(() => {
    if (!data) {
      return []
    }

    const { addons, features } = data.promoAddons
    const index: Record<string, (typeof addons)[0]> = addons.reduce((acc, addon) => (
      { ...acc, [addon.itemId]: addon }
    ), {})

    return features.reduce((acc, feature) => (
      index[feature.chargebeeId] ? [...acc, { ...index[feature.chargebeeId], ...feature }] : acc
    ), [])
  }, [data])

  useEffect(() => {
    setNav('no-sign-in')
    setSide('hidden')
    return () => {
      resetNav()
      resetSide()
    }
  }, [])

  if (loading || !data) {
    return <Loader active size="big" />
  }

  const submit = (token?: string) => {
    setApplyLoading(true)
    const variables = { ids: Array.from(selectedAddons), token }
    attachPromoAddons({ variables }).then(() => {
      Notifications.success('Promotion successfully applied!')
      Router.redirect('/dashboard')
    }).catch(error => {
      const message = error.graphQLErrors[0]?.message
      Notifications.error(message)
      setConfirmed(false)
      setShowCard(true)
    }).finally(() => {
      setApplyLoading(false)
    })
  }

  const toggleAddon = ({ id, price }: { id: string, price: string }) => {
    setSelectedAddons(prev => {
      const newSet = new Set(prev)
      const multiplier = newSet.has(id) ? -1 : 1
      newSet[multiplier === 1 ? 'add' : 'delete'](id)

      const basePrice = new Decimal(price)
      const discountPrice = basePrice.minus(couponDiscount(basePrice, data.promoAddons.coupon))
      totalRef.current = totalRef.current.add(discountPrice.times(multiplier))
      return newSet
    })
  }

  const cardTokenizer = () => {
    setApplyLoading(true)
    cardRef.current.tokenize().then((cardToken: CardToken) => {
      submit(cardToken.token)
    }).catch(() => {
      Notifications.error('The card information is invalid')
      setApplyLoading(false)
    })
  }

  const paymentSource = data.account.customer.primaryPaymentSource
  const cardLogo = CARD_LOGO[paymentSource.type] || CARD_LOGO[paymentSource.card.brand] || CardOther

  const buttonSubmit = showCard ? cardTokenizer : submit
  const canSubmit = selectedAddons.size && confirmed && !cardErrors.length && (!showCard || cardValid)

  return (
    <fieldset disabled={applyLoading}>
      <div className="checkout-title">
        <p>|</p>
        <SimpleText size="title2" color="grey">Black Friday</SimpleText>
      </div>
      <SimpleBox className="checkout checkout-new" display="flex" justifyContent="center" pt={4}>
        <Form name="checkout" className="checkout-first-block">
          <SimpleBox display="flex" alignItems="center" gap={12} width="100%">
            <SimpleBox className="checkout-order-circle">
              <SimpleText size="title2" color="white" weight="bold"></SimpleText>
            </SimpleBox>
            <SimpleText size="title3" color="black">
              <span className="checkout-order-title">
                Add Extra Features
              </span>
            </SimpleText>
          </SimpleBox>
          <SimpleBox className="addon-card-row" mb={5}>
            <SimpleBox mt={3} display="flex" flexDirection="row" className="addon-row">
              {features.map(feature => (
                <AddonCard
                  addon={feature}
                  key={feature.id}
                  handleClickOnAdd={event => {
                    event.preventDefault()
                    event.currentTarget.blur()
                    toggleAddon(feature)
                  }}
                  isSelected={selectedAddons.has(feature.id)}
                  coupon={data.promoAddons.coupon}
                  planPeriod={12}
                  freeMonths={0}
                />
              ))}
            </SimpleBox>
          </SimpleBox>
          <SimpleBox display="flex" alignItems="center" gap={12} width="100%">
            <SimpleBox className="checkout-order-circle">
              <SimpleText size="title2" color="white" weight="bold"></SimpleText>
            </SimpleBox>
            <SimpleText size="title3" color="black">
              <span className="checkout-order-title">
              Select Your Preferred Payment Method
              </span>
            </SimpleText>
          </SimpleBox>
          <SimpleBox mb={5}>
            {showCard && (
              <PaymentMethod
                isCardOpen
                title={t('checkout.sections.payment_method.card.title')}
                icon={<img height={20} src={PaymentCardsImage} alt="cards" />}
              >
                <SimpleBox marginTop={25} paddingTop={18} borderTop="1px solid #E8EAF0">
                  <CardComponent
                    forwardRef={cardRef}
                    onErrors={setCardErrors}
                    onValidChanged={state => setCardValid(state)}
                    nameDisabled
                  />
                </SimpleBox>
              </PaymentMethod>
            ) || (
              <PaymentMethod
                title={''}
                icon={(
                  <>
                    <Image src={cardLogo} alt="logo" />
                    **** **** **** {paymentSource.card.last4}
                  </>
                )}
              />
            )}
          </SimpleBox>
          <SimpleBox mb={6}>
            <Form.Checkbox
              name="confirm"
              size="small"
              label={`
                By checking here you agree with the promotion described above and
                agree with the immediate charge of ${toMoney(totalRef.current.toNumber())}.
              `}
              disabled={totalRef.current.isZero()}
              checked={confirmed}
              onChange={() => setConfirmed(!confirmed)}
            />
          </SimpleBox>
          <SimpleBox>
            <Form.Button
              color="red"
              floated="right"
              loading={applyLoading}
              disabled={!canSubmit}
              size="huge"
              content="Continue"
              onClick={() => buttonSubmit()}
              primary
            />
          </SimpleBox>
        </Form>
      </SimpleBox>
    </fieldset>
  )
}

export default Promos
