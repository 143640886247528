import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend'
import Session from '~shared/storages/session'
import { isEnv } from './config'

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    lng: Session.locale || 'en',
    load: 'currentOnly',
    fallbackLng: 'en',
    debug: !isEnv('production'),
    interpolation: {
      escapeValue: false,
      prefix: '%{',
      suffix: '}',
    },
    backend: {
      loadPath: '/translations.%{lng}.json',
    },
  })

export default i18n
export const t = i18n.t
