import React from 'react'
import { useTranslation } from 'react-i18next'
import { SimpleBox } from '../../SimpleBox'
import { Image, Label } from 'semantic-ui-react'

const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')

const Tags: React.FC<{
  activateNow: boolean;
  trialEnabled?: boolean;
  reduced?: boolean;
}> = ({ activateNow, trialEnabled, reduced }) => {
  const { t } = useTranslation()
  const className = activateNow ? 'checkout-tags checkout-tags-single' : 'checkout-tags'

  return (
    <SimpleBox display="inline-block" ml="auto" className={className}>
      <Label.Group color="grey" size="large">
        {!activateNow && (
          <Label className="label-text">
            <Image as="img" src={PlanCheckMarkIcon} height={20} width={20} />
            {t('checkout.header.trial')}
          </Label>
        )}
        <Label className="label-text">
          <Image as="img" src={PlanCheckMarkIcon} height={20} width={20} />
          {t('checkout.header.mbg', { days: (trialEnabled || reduced) ? '30' : '60' })}
        </Label>
      </Label.Group>
    </SimpleBox>
  )
}

export default Tags
