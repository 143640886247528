import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import { SimpleBox } from '../../SimpleBox'
import { SimpleCard } from '../../SimpleCard'
import { SimpleText } from '../../SimpleText'
import { Points } from '../../Plans/components/Single/components'
import { Checkbox, Label } from 'semantic-ui-react'
import Breakdown from '../Breakdown'
import { ChargebeeCoupon } from '../../../utils/coupon'
import { Addon, Plan } from '../../../type'
import { Discount } from '../../../fragments/modal/payment/DiscountNew'
import { Feature } from '../../../types/checkout'
import { toMoneyWithoutCalculation as toMoney } from '../../../shared/format'
import { calculateCouponDiscount, generateFeaturesList } from '../../../graphql/checkout'
import Decimal from 'decimal.js'
import SummaryCardHeader from './HeaderComponent'
import { BlackFridayBanner } from '../../../components/SeasonalDiscountBanner/BlackFridayBanner'

const ShieldRedIcon = require('~assets/images/icons/shield-red.svg')
const AlertIcon = require('~assets/images/icons/icon-alert.svg')
const ArrowUp = require('~assets/images/icons/icon-arrow-up.svg')
const ArrowDown = require('~assets/images/icons/icon-arrow-down.svg')

interface SummaryCardProps {
  plan: Plan
  total: number
  coupon?: ChargebeeCoupon
  activateNow: boolean
  extraUserAddon?: Addon
  addAnotherUser: boolean
  handleToggleAddUser: () => void
  firstPlanId: string
  handleCouponChange: (coupon?: ChargebeeCoupon) => void
  selectedFeatures: Feature[]
  bienniallyFreeMonths?: number
  priceWithAddon: number
  allowCoupon?: boolean
  seasonalDiscountBanner?: boolean
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  total,
  coupon,
  activateNow,
  plan,
  extraUserAddon,
  addAnotherUser,
  handleToggleAddUser,
  firstPlanId,
  handleCouponChange,
  selectedFeatures,
  bienniallyFreeMonths,
  priceWithAddon,
  allowCoupon,
  seasonalDiscountBanner
}) => {
  const { t } = useTranslation()
  const [showPoints, setShowPoints] = useState(false)
  const [isMobile, setMobile] = useState<boolean>(false)
  const price = parseFloat(plan.price)
  const points = useMemo(() => {
    if (plan?.item) {
      const translated = t(`record.chargebee--item.${plan.item.id}.features`, { defaultValue: '' })
      const featuresList = translated ? translated.split('\n') : plan.item.featuresList
      return generateFeaturesList({ featuresList }, activateNow, t('checkout.header.trial'))
    }
  }, [plan?.item, activateNow])

  const planPeriod = plan.period || 12
  const period = { 1: 'month', 12: 'year', 24: '2_years' }[planPeriod]

  const [EUPOriginal, EUPDiscounted] = useMemo<[string, string]>(() => {
    if (!extraUserAddon) {
      return ['', '']
    }

    const basePrice = new Decimal(extraUserAddon.price)
    const discountPrice = basePrice.minus(calculateCouponDiscount(basePrice, coupon)).toNumber()
    const options = { maximumFractionDigits: 0, minimumFractionDigits: 0 }
    return [toMoney(basePrice.toNumber(), options), toMoney(discountPrice, options)]
  }, [extraUserAddon, coupon])

  const userCount = (plan.item?.users || 1) + (addAnotherUser ? 1 : 0)
  const planName = t(`record.chargebee--item_price.${plan.id.split('-')[0]}.name`, { defaultValue: plan.name })
  const nameParts = planName.replace(/\)$/, '').split('(')

  const handleTogglePoints = () => {
    setShowPoints(!showPoints)
  }

  const detectMobile = useCallback(() => {
    setMobile(window.innerWidth <= 768)
  }, [isMobile])

  useEffect(() => {
    detectMobile()

    window.addEventListener('resize', detectMobile)
    return () => window.removeEventListener('resize', detectMobile)
  }, [])

  const renderHeaderComponent = () => (
    <SummaryCardHeader
      userCount={userCount}
      coupon={coupon}
      price={price}
      planPeriod={planPeriod}
      activateNow={activateNow}
      bienniallyFreeMonths={bienniallyFreeMonths}
    />
  )

  return (
    <SimpleCard className="checkout-summary-card">
      <div className="first-block">
        <SimpleBox
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          gap={12}
        >
          <img src={ShieldRedIcon} alt="shield" width={42} />
          <SimpleBox
            display="flex"
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="center"
            gap={4}
          >
            <SimpleText size="title3" color="black">
              <span className="checkout-summary-plan-title show-on-mobile">
                VirtualShield
              </span>
            </SimpleText>
            <SimpleText size="title3" color="red" whiteSpace="nowrap">
              <span className="checkout-summary-plan-title">
                {nameParts[0]}
              </span>
            </SimpleText>
            <SimpleText size="title3">
              <span className="checkout-summary-plan-title">
                ({nameParts[1]})
              </span>
            </SimpleText>
          </SimpleBox>
        </SimpleBox>

        {(seasonalDiscountBanner && coupon) && <BlackFridayBanner coupon={coupon} />}

        <SimpleBox width="100%" flexDirection="column" alignItems="flex-start" marginTop="12px" className="hide-on-tablet-only">
          {renderHeaderComponent()}
        </SimpleBox>
        <div className="checkout-points-wrapper show-on-mobile">
          <div className="checkout-points-toggle-row" onClick={handleTogglePoints}>
            <span>{t('checkout.sections.summary.plan_features')}</span>
            <div className="checkout-points-toggle-row-icon"><img src={showPoints ? ArrowUp : ArrowDown} /></div>
          </div>
          {showPoints && <Points
            items={points}
            secondary={true}
            footer={null}
            newCheckout={true}
          />}
        </div>
        <div className="show-on-web">
          <Points
            items={points}
            secondary={true}
            footer={null}
            newCheckout={true}
          />
        </div>
        {extraUserAddon && (
          <SimpleBox className="summary-card-extra-features">
            <SimpleText size="buttonText" color="black">
              <span className="checkout-mobile-text-12">
                {t('checkout.sections.summary.add_user.title')}
              </span>
            </SimpleText>
            <SimpleBox
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              gap={4}
              marginTop={8}
            >
              <Checkbox
                className="add-one-checkbox"
                onChange={handleToggleAddUser}
                style={{ isolation: 'isolate' }}
                checked={addAnotherUser}
                toggle
              />
              <Markdown className="checkout-add-user">
                {t('checkout.sections.summary.add_user.label', (
                  (EUPOriginal !== EUPDiscounted) ? ({
                    'base_price': `~~${EUPOriginal}~~`,
                    'price': `**${EUPDiscounted}**`,
                    'period': t(`checkout.sections.summary.period.${period}`),
                  }) : ({
                    'base_price': '',
                    'price': `${EUPDiscounted}`,
                    'period': t(`checkout.sections.summary.period.${period}`),
                  })
                ))}
              </Markdown>
              <SimpleBox
                display="flex"
                flexDirection="column"
                position="relative"
                className="alert-box"
              >
                <Label pointing="below" className="alert-label">
                  {t('checkout.sections.summary.add_user.description')}
                </Label>
                <img src={AlertIcon} alt="alert" width={16} />
              </SimpleBox>
            </SimpleBox>
          </SimpleBox>
        )}
      </div>
      <div className="second-block">
        <SimpleBox width="100%" flexDirection="column" alignItems="flex-start" marginTop="12px" className="show-on-tablet-only">
          {renderHeaderComponent()}
        </SimpleBox>
        <Breakdown
          plan={plan}
          total={total}
          coupon={coupon}
          activateNow={activateNow}
          priceWithAddon={priceWithAddon}
          selectedFeatures={selectedFeatures}
          bienniallyFreeMonths={bienniallyFreeMonths}
        />
        {allowCoupon && (
          <div className="discountForm">
            <Discount
              planId={firstPlanId}
              current={coupon}
              onCouponFound={handleCouponChange}
              onRemoveCoupon={handleCouponChange}
              showCouponInput={isMobile}
            />
          </div>
        )}
      </div>
    </SimpleCard>
  )
}

export default SummaryCard
