import React, { useMemo } from 'react'
import { Button, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import Decimal from 'decimal.js'
import { ChargebeeCoupon } from '../../../utils/coupon'
import { toMoneyWithoutCalculation as toMoney } from '../../../shared/format'
import { calculateCouponDiscount as couponDiscount } from '../../../graphql/checkout'
import { ICartExtraFeatures } from '../../../types/checkout'

interface AddonCardProps {
  addon: ICartExtraFeatures
  isSelected: boolean
  coupon?: ChargebeeCoupon
  planPeriod: number
  freeMonths: number
  handleClickOnAdd?: (event: React.MouseEvent<HTMLElement>) => void
}

const AddonCard: React.FC<AddonCardProps> = ({
  addon, isSelected, coupon, planPeriod, freeMonths, handleClickOnAdd,
}) => {
  const { t } = useTranslation()
  const { id, title, description, imageUrl: icon, price } = addon
  const [originalPrice, priceWithDiscount] = useMemo(() => {
    const basePrice = new Decimal(price)
    const discountPrice = basePrice.minus(couponDiscount(basePrice, coupon))
    return [basePrice.div(planPeriod), discountPrice.div(planPeriod + freeMonths)].map(v => toMoney(v.toNumber()))
  }, [coupon, price, freeMonths])

  return (
    <div className={`addon-card${isSelected ? ' addon-card-selected' : ''}`} onClick={handleClickOnAdd}>
      <div className="detail">
        <div className="title">{t(`record.cart_extra_feature.${id}.title`, { defaultValue: title })}</div>
        <div className="subTitle">{t(`record.cart_extra_feature.${id}.description`, { defaultValue: description })}</div>
        {(originalPrice !== priceWithDiscount && (
          <div className="price-box price-discount-box">
            <div className="price">
              <Markdown>{t('checkout.sections.addons.price', { amount: priceWithDiscount })}</Markdown>
            </div>
            <div className="original-price-row">
              <div className="original-price">
                {t('checkout.sections.addons.original_price', { amount: originalPrice })}
              </div>
            </div>
          </div>
        )) || (
          <div className="price-box price">
            <Markdown>{t('checkout.sections.addons.price', { amount: originalPrice })}</Markdown>
          </div>
        )}
        <div className="logo">
          <Image as="img" src={icon} />
        </div>
      </div>
      <Button className={`addon-button${isSelected ? '-selected' : ''}`}>
        {t(`checkout.sections.addons.${isSelected ? 'added' : 'add'}`)}
      </Button>
    </div>
  )
}

export default AddonCard
