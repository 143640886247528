import React, { FC, useCallback } from 'react'
import { Button, Input, Card, Header } from 'semantic-ui-react'
import { gql, useMutation } from '@apollo/client'
import Notifications from '../../shared/notifications'
import style from './License.module.scss'

type LicenseInfo = { license: string; downloadUrl: string }

const REMOVE_LICENSE = gql`
  mutation { revokeAppLicense(key: "windows-antivirus", all: true) }
`

const ADD_LICENSE = gql`
  mutation { createAntivirusLicense { license downloadUrl } }
`

type Props = {
  license?: string
  devices?: number
  onUpdate: (value: string) => void
}

const License: FC<Props> = ({ license, devices, onUpdate }) => {
  const [addLicense, { loading: creating }] = useMutation<{ createAntivirusLicense: LicenseInfo }>(ADD_LICENSE)
  const [removeLicenses, { loading: revoking }] = useMutation<{ revokeAppLicense: boolean }>(REMOVE_LICENSE)
  const disabled = creating || revoking

  const onRevoke = useCallback(() => {
    // eslint-disable-next-line no-alert
    if (license && window.confirm(
      `Are you sure you want to revoke this license key? 
      All devices that are currently using this key will need to be reactivated.`
    )
    ) {
      removeLicenses().then(({ data }) => {
        data?.revokeAppLicense && onUpdate('')
      }).catch(() => {
        Notifications.error('Failed to revoke license key')
      })
    }
  }, [license])

  const onGenerate = useCallback(() => {
    if (!license) {
      addLicense().then(({ data }) => {
        data?.createAntivirusLicense?.license && onUpdate(data.createAntivirusLicense.license)
      }).catch(() => {
        Notifications.error('Failed to generate license key')
      })
    }
  }, [license])

  return (
    <Card fluid className="dense">
      <Card.Content className={style['container']}>
        <div className={style['description']}>
          <Header as="h5">License Key</Header>
          <p>
            For the Windows app, the license will activate automatically upon download.
            However, for the Android app, you&apos;ll need to manually paste the license key.
          </p>
          <p>
            Your plan supports up to {devices === 1 ? '1 device' : `${devices} devices`}.
          </p>
        </div>
        <div className={style['input']}>
          {!license && (
            <Button secondary size="large" onClick={onGenerate} content="Generate Key" floated="right" disabled={disabled} />
          ) || (
            <Input
              fluid
              readOnly
              value={license || ''}
              placeholder="Search..."
              action= {
                <Button onClick={() => navigator.clipboard.writeText(license)} content="Copy" disabled={disabled} />
              }
            />
          )}
          {license && (
            <Button color="red" onClick={onRevoke} content="Revoke" floated="right" disabled={disabled} />
          )}
        </div>
      </Card.Content>
    </Card>
  )
}

export { License }
