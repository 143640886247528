import React, { FC } from 'react'
import { Button, Image } from 'semantic-ui-react'
import { SimpleBox } from '../SimpleBox'

const IMAGE = require('../../assets/images/identity-setup-onboarding.png')

const Upgrade: FC = () => {
  const openChat = () => {
    // @ts-ignore
    window.fcWidget.open()
  }

  return (
    <SimpleBox className="sign-up">
      <Image src={IMAGE} />
      <h5>Antivirus Not Activated!</h5>
      <SimpleBox className="steps" marginTop="33px" marginBottom="33px">
        You currently don&#39;t have antivirus in your VirtualShield plan.
      </SimpleBox>
      <Button primary={true} size="large" onClick={openChat}>
        Activate Protection
      </Button>
    </SimpleBox>
  )
}

export { Upgrade }
