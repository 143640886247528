import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'

const ExternalLink: FC<{ href: string, children: JSX.Element }> = ({ href, children }) => (
  <a href={href} target="_blank" rel="noreferrer">{children}</a>
)

export const TOS: FC = () => {
  const { t } = useTranslation()

  return (
    <Markdown components={{ a: ExternalLink }} className="text--x-small">
      {t('checkout.terms')}
    </Markdown>
  )
}
