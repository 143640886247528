import React, { FC, useCallback, useMemo, useState } from 'react'
import { Dropdown, StrictDropdownProps } from 'semantic-ui-react'
import { action } from '@decorators'
import Session from '~shared/storages/session'

type Props = Omit<StrictDropdownProps, 'value' | 'options' | 'onChange'> & {
  onChange?: (value: string) => void
  options: string[]
  sign?: boolean
}

function signFor(currency: string): string {
  const base = new Intl.NumberFormat('en', { style: 'currency', currency }).formatToParts(1)
  return base.find(({ type }) => type === 'currency')?.value || ''
}

export const CurrencyPicker: FC<Props> = ({ options, sign, onChange, className, ...props }) => {
  const [value, setValue] = useState(Session.currency || 'USD')
  const handleChange = useCallback(action((_: any, { value }: { value: string }) => {
    Session.currency = value
    setValue(value)
    onChange?.(value)
  }), [onChange])

  const dropdownOptions = useMemo(() => options.map(value => ({
    value, key: value, text: (`${value}${sign ? ` (${signFor(value)})` : ''}`),
  })), [options])

  if (options.length <= 1) {
    return null
  }

  return <Dropdown
    className={`currencyPicker ${className || ''}`}
    options={dropdownOptions}
    onChange={handleChange}
    value={options.indexOf(value) === -1 ? 'USD' : value}
    {...props}
  />
}
