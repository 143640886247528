import React, { FC, useCallback, useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Icon, Dropdown, StrictDropdownProps, SemanticICONS } from 'semantic-ui-react'
import { action } from '@decorators'
import Session from '~shared/storages/session'

type Props = Omit<StrictDropdownProps, 'value' | 'options' | 'onChange' | 'loading'> & {
  flag?: boolean
  label?: 'key' | 'name' | false
}

const QUERY = gql`
  { languages }
`

const OVERRIDES: Record<string, string> = {
  'cs': 'cz',
  'da': 'dk',
  'el': 'gr',
  'et': 'ee',
  'en': 'us',
  'ga': 'ie',
  'lb': 'lu',
  'sl': 'si',
  'sv': 'se',
  'zh': 'cn',
}

function toFlagName(value: string): string {
  const val = value.split('-').at(-1)?.toLowerCase() || 'us'
  return OVERRIDES[val] || val
}

export const LocalePicker: FC<Props> = ({ label, flag, className, icon, ...props }) => {
  const { data, loading } = useQuery<{ languages: string[] }>(QUERY)
  const { t, i18n } = useTranslation()

  const onChange = useCallback(action((_: any, { value }: { value: string }) => {
    i18n.changeLanguage(value)
    Session.locale = value
  }), [])

  const options = useMemo(() => data?.languages.map(value => ({
    value, key: value, flag: (flag && toFlagName(value)), text: (
      label === false ? undefined : (label === 'key' && value.toUpperCase()) || t(`languages.${value}`)
    ),
  })), [data?.languages, i18n.language])

  return <span>
    {icon && (typeof icon === 'string' ? <Icon name={icon as SemanticICONS} /> : icon)}
    <Dropdown
      className={`localePicker ${className || ''}`}
      value={Session.locale || 'en'}
      options={options || []}
      onChange={onChange}
      loading={loading}
      {...props}
    />
  </span>
}
