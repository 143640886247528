import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import { SimpleBox } from '../components/SimpleBox'

type activateProps = {
  months?: number;
  activateNow: boolean;
  setActivateNow: (value: boolean) => void;
  bienniallyFreeMonths?: number;
};

export const ActivateNow: FC<activateProps> = ({
  months,
  activateNow,
  setActivateNow,
  bienniallyFreeMonths = 3,
}) => {
  const { t } = useTranslation()
  if (!months || months < 12 || !bienniallyFreeMonths) {
    return null
  }

  return (
    <SimpleBox mt={3} className="activate-now">
      <label>
        <input
          type="checkbox"
          name="activateNow"
          checked={activateNow}
          onChange={() => setActivateNow(!activateNow)}
        />{' '}
        <Markdown>
          {t('checkout.sections.payment_method.activate_now', {
            'base_months': months,
            'free_months': bienniallyFreeMonths,
            'total_months': months + bienniallyFreeMonths,
          })}
        </Markdown>
      </label>
    </SimpleBox>
  )
}
