import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Button, Form, Grid, Header, Icon, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { action, observer } from '../decorators'
import Notifications from '../shared/notifications'
import Router from '../shared/router'
import Session from '../shared/storages/session'
import { SimpleBox } from '../components/SimpleBox'
import { SimpleLayout } from '../components/SimpleLayout'
import { emailRegex } from '../const'
import { useNavigation } from '../hooks/useNavigation'
import { gql, useMutation } from '@apollo/client'
import { SimpleCard } from '../components/SimpleCard'

const AuthCover = require('~assets/images/auth-cover.png')
const Logo = require('~assets/images/logo-black.svg')
const CheckIcon = require('~assets/images/icons/tick-green.svg')

export const ERROR_TYPES = {
  'not_found': 'email or password invalid.',
  'unconfirmed': 'email is not confirmed.',
  'disabled': 'access is disabled.',
}

const Index: FC & { authorize: boolean | string; auth: string } = () => {
  const { setNav, resetNav, setSide, resetSide } = useNavigation()
  const { t } = useTranslation()

  const [tokenMutation] = useMutation(gql`
    mutation confirmUser($token: String!) {
      confirmUser(token: $token)
    }
  `)
  const [authy, setAuthy] = useState(false)

  const urlToken = Router.qs.token
  const promoResult = Router.qs.promo
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [remind, setRemind] = useState(false)
  const [token, setToken] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [userConfirmed, setUserConfirmed] = useState(false)

  const onSubmit = () => {
    if(loading) {
      return
    }

    setLoading(true)
    const mutation = Session.authenticate(email, password, token)
    return mutation.client.then(
      action(() => {
        setLoading(false)
        if (mutation.data?.me) {
          const ref = Router.qs.ref as string
          Router.redirect(!ref ? '/apps' : `/${ref}`)
        } else {
          const type = mutation.errors?.[0]?.extensions?.error_type

          if (type === 'authy') {
            setAuthy(true)
          } else {
            const extra = ERROR_TYPES[type as keyof typeof ERROR_TYPES] || ''
            Notifications.error(mutation.error() + (extra ? ': ' : '') + extra)
          }
        }
      })
    )
  }

  const isEmailValid = emailRegex.test(email)
  const hasPassword = password !== ''
  const isReady = isEmailValid && hasPassword

  useEffect(() => {
    if (urlToken) {
      tokenMutation({ variables: { token: urlToken } }).then(result => {
        setUserConfirmed(!!result?.data?.confirmUser)
      })
    }
  }, [urlToken])

  useEffect(() => {
    setNav('hidden')
    setSide('hidden')
    return () => {
      resetNav()
      resetSide()
    }
  }, [])

  const emailPasswordMarkup = (
    <>
      <SimpleBox mt={6}>
        <Form.Input
          size="large"
          type="email"
          name="email"
          label={t('sign-in.email')}
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          icon={
            isEmailValid && (
              <Image
                src={CheckIcon}
                height={22}
                style={{ position: 'absolute', top: '50%', right: 12, marginTop: -12 }}
              />
            )
          }
        />
        <Form.Input
          size="large"
          type={showPassword ? 'text' : 'password'}
          name="password"
          label={t('sign-in.password')}
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          icon={<Icon name={showPassword ? 'eye' : 'eye slash'} link onClick={() => { setShowPassword(!showPassword) }} />}
        />
        <Grid columns={2}>
          <Grid.Column>
            <Form.Checkbox
              name="remind"
              label={t('sign-in.remember_me')}
              checked={remind}
              onChange={() => setRemind(!remind)}
            />
          </Grid.Column>
          <Grid.Column align="right">
            <a href="/forgot-password">{t('sign-in.forgot_password')}</a>
          </Grid.Column>
        </Grid>
      </SimpleBox>
      <SimpleBox mt={7}>
        <Form.Button color="red" loading={loading} disabled={!isReady} size="huge" content="Sign In" fluid primary />
      </SimpleBox>
    </>
  )

  const authyMarkup = (
    <>
      <Form.Field
        name="authy"
        className="authy"
        label="Token"
        maxLength={6}
        required
        onChange={(e: ChangeEvent<HTMLInputElement>) => setToken(e.target.value)}
      />
      <Form.Button loading={loading} disabled={!isReady} size="huge" content="Confirm" fluid primary />
    </>
  )

  const userConfirmedMarkup = (
    <SimpleBox marginTop="20px" color="black">
      <SimpleCard backgroundColor="rgba(26, 178, 51, 0.15)" rounded="subtile">Your email address has been successfully verified!</SimpleCard>
    </SimpleBox>
  )

  const promoMarkup = (
    <SimpleBox marginTop="20px" color="black">
      <SimpleCard backgroundColor="rgba(26, 178, 51, 0.15)" rounded="subtile">
        {promoResult === 'success' ? 'The promotion has been successfully applied!' : 'The promotion has already been applied!'}
      </SimpleCard>
    </SimpleBox>
  )

  return (
    <SimpleLayout>
      <SimpleLayout.Section oneThird>
        <SimpleBox height="100%" backgroundColor="#fff" className="form">
          <SimpleBox mb={5} mt={5}>
            <Image src={Logo} height={38} id="logo" />
          </SimpleBox>
          <Form id="auth" onSubmit={onSubmit}>
            <Header as="h3">
              {t('sign-in.title')}
            </Header>
            {promoResult && promoMarkup}
            {userConfirmed && userConfirmedMarkup}
            {authy ? authyMarkup : emailPasswordMarkup}
            <SimpleBox mt={6}>
              <Grid columns={2}>
                <Grid.Column verticalAlign="middle" align="right">
                  <div className="text">{t('sign-in.new_account')}</div>
                </Grid.Column>
                <Grid.Column>
                  <Button as="a" href="/pricing" secondary>
                    {t('sign-in.try')}
                  </Button>
                </Grid.Column>
              </Grid>
            </SimpleBox>
          </Form>
        </SimpleBox>
      </SimpleLayout.Section>
      <SimpleLayout.Section>
        <SimpleBox position="absolute" left={56} bottom={0} zIndex={6} p={'112px 0'}>
          <Header as="h1">{t('sign-in.banner')}</Header>
        </SimpleBox>
        <SimpleBox
          width="100%"
          height="100%"
          backgroundImage={`url('${AuthCover}')`}
          backgroundSize="846px 900px"
          backgroundPosition="right center"
          backgroundRepeat= "no-repeat"
        />
      </SimpleLayout.Section>
    </SimpleLayout>
  )
}

Index.authorize = false
Index.auth = '/apps'

export default observer(Index)
