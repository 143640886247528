import React, { useMemo } from 'react'
import { SimpleBox } from '../../SimpleBox'
import { SimpleText } from '../../SimpleText'
const ArrowUp = require('~assets/images/icons/icon-arrow-up.svg')
const ArrowDown = require('~assets/images/icons/icon-arrow-down.svg')

interface PaymentMethodProps {
  title: string
  isCardOpen?: boolean
  icon: React.ReactNode
  children?: React.ReactNode
  handleToggleCard?: () => void
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  title, isCardOpen, icon, handleToggleCard, children,
}) => {
  const boxSettings = useMemo(() => ({ mt: 3, borderRadius: '8px', backgroundColor: 'white', padding: '20px' }), [])
  const header = useMemo(() => (
    <>
      <SimpleBox display="flex" alignItems="center">
        <SimpleText size="title2">{title}</SimpleText>
        {icon}
      </SimpleBox>
      {handleToggleCard && (
        <div className="toggleButton">
          <img src={isCardOpen ? ArrowUp : ArrowDown} />
        </div>
      )}
    </>
  ), [title, icon, isCardOpen])

  if (!isCardOpen) {
    return (
      <SimpleBox cursor="pointer" onClick={handleToggleCard} {...boxSettings}>
        <SimpleBox display="flex" justifyContent="space-between" alignItems="center">
          {header}
        </SimpleBox>
      </SimpleBox>
    )
  }

  return (
    <SimpleBox {...boxSettings}>
      <SimpleBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        cursor="pointer"
        onClick={handleToggleCard}
      >
        {header}
      </SimpleBox>
      {children}
    </SimpleBox>
  )
}

export default PaymentMethod
