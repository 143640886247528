import React, { RefObject, useEffect, useState, useRef, VFC } from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Portal, Table } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { PAYPAL_URL } from '../../const'
import { ChargebeeCoupon } from '../../utils/coupon'
import Mutation from '../../shared/mutation'
import Notifications from '../../shared/notifications'
import { AppleBtn, GoogleBtn, PaypalBtn } from '../../shared/paypal-btn'
import Router from '../../shared/router'
import Session from '../../shared/storages/session'
import { CardToken, PaypalToken, Subscribe } from '../../type'
import { PaymentData } from './ICardProps'
import { toMoneyWithoutCalculation as toMoney, resolveCurrency } from '../../shared/format'
import { CHARGEBEE_PAYMENT_SOURCE_TYPES as PAYMENT_TYPES } from '../../shared/types'
import {
  mutationPaypalMutation,
  mutationSubscribeMutation,
  createPaymentIntentMutation,
} from '../../graphql/checkout'

const Chargebee = require('chargebee')

const ZIP_ERROR_MATCHER = '27'
const ZIP_ERROR_MESSAGE =
  'Error: The ZIP code does not match that on file with your bank. Please contact your bank to verify your zip code.'

type CreatePaymentIntent = { createPaymentIntent: Hash }
type Props = {
  type?: PAYMENT_TYPES
  data: PaymentData
  priceIds: string[]
  addonIds?: string[]
  cardRef: RefObject<any>
  captchaRef: RefObject<any>
  loadingState: ReturnType<typeof useState<boolean>>
  coupon?: ChargebeeCoupon
  disabled?: boolean
  activateNow: boolean
  promotion: boolean
  paypalReturnPath: string
  purchaseFinished?: () => void
  price?: number
  subscriptionId?: string
  checkoutConfigId?: string
  planDetail?: { period: number, periodUnit: number }
}

const Submit: VFC<Props> = ({
  type = 'CARD',
  data: paymentData,
  priceIds,
  addonIds,
  cardRef,
  captchaRef,
  loadingState,
  coupon,
  disabled,
  activateNow,
  promotion,
  paypalReturnPath,
  purchaseFinished,
  price = 0,
  subscriptionId = '',
  checkoutConfigId = '',
  planDetail,
}) => {
  const createPaymentIntent = new Mutation<CreatePaymentIntent>(createPaymentIntentMutation)
  const subscribeMutation = new Mutation<Subscribe>(mutationSubscribeMutation)
  const paypalMutation = new Mutation<PaypalToken>(mutationPaypalMutation)
  const [confirmationCancel, setConfirmationCancel] = useState<() => void>()
  const confirmationModal = useRef<HTMLDialogElement>(null)
  const { t } = useTranslation()

  // After success, properly redirect to the page with the necessary parameters
  const successRedirect = () => {
    const params = new URLSearchParams({
      checkoutSubscriptionId: subscriptionId,
      checkoutConfigId,
    })

    activateNow && params.append('activateNow', '1')

    if (planDetail) {
      params.append('period', planDetail.period.toString())
      params.append('periodUnit', planDetail.periodUnit.toString())
    }

    params.append('planId', priceIds[0])
    Router.redirect(`/welcome?${params}`)
  }

  // Perform the subscription mutation and handle response
  const submit = (extra: { couponCode?: string } & Hash, reset?: () => void) => {
    if (subscribeMutation.loading) {
      return
    }

    const input = {
      type,
      priceIds,
      activateNow,
      email: paymentData.email,
      signupSource: location.pathname.split('/')[1],
      checkoutSubscriptionId: subscriptionId,
      currency: resolveCurrency(),
      offer: Router.qs?.offer?.toString() || undefined,
      promotion,
      ...Session.affiliate,
      ...extra,
    }

    subscribeMutation.exec({ input }).then(action(() => {
      const result = subscribeMutation.data?.result
      if (result) {
        Notifications.success('Account created successfully')
        Session.authenticated = true
        Session.accessToken = result.token
        purchaseFinished?.()
        localStorage.removeItem('vs_checkout_cache')
        successRedirect()
      } else {
        reset?.()
        loadingState[1](false)
        const error = subscribeMutation.error()
        const message = error.includes(ZIP_ERROR_MATCHER) ? ZIP_ERROR_MESSAGE : error
        Notifications.error(message, { timing: 8000 })
      }
    }))
  }

  // Submit from a payload that contains a token
  const submitWithToken = (result: CardToken, reset?: () => void) => {
    submit({
      zip: result.zip || paymentData.zip,
      firstName: result.firstName || paymentData.firstName,
      lastName: result.lastName || paymentData.lastName,
      couponCode: coupon?.id || '',
      card: { token: result.token },
    }, reset)
  }

  // Submit PayPal token and coupon code
  const submitPayPal = (token: string, couponCode?: string) => {
    submit({
      zip: '0000',
      couponCode: coupon?.id || couponCode || '',
      paypal: { token },
    })
  }

  // Helper to create the proper payment intent
  const requestPaymentIntent = async (method: string) => {
    const variables = { amount: Math.ceil(price * 100), currency: resolveCurrency(), method }
    return (await createPaymentIntent.exec(variables)).data?.createPaymentIntent
  }

  // Handle the proper payment flow with Chargebee.js token generation with 3DS
  const handleChargebeeJs = () => {
    const cardTokenizer = cardRef.current
    cardTokenizer.tokenize().then(async (result: { token: string }) => {
      const paymentIntent = await requestPaymentIntent('card')
      if (paymentIntent) {
        const threeDSHandler = await Chargebee.getInstance().load3DSHandler()
        threeDSHandler.setPaymentIntent(paymentIntent)

        await threeDSHandler.handleCardPayment({ cbToken: result.token })
        submitWithToken({ token: (paymentIntent.id as string) })
      }
    }).catch(() => {
      Notifications.error('Unable to proceed with the provided card information.')
      loadingState[1](false)
    })
  }

  // Handle submit with captcha capturing
  const handleSubmit = () => {
    if (loadingState[0]) {
      return
    }

    const captcha = captchaRef.current
    captcha.executeAsync().then((token: string) => {
      captcha.reset()
      if (token) {
        loadingState[1](true)

        // type === 'CARD'
        handleChargebeeJs()
      }
    })
  }

  // Handle the PayPal payment preparation flow
  const handlePayPal = () => {
    if (loadingState[0]) {
      return
    }

    loadingState[1](true)
    const payload = {
      id: priceIds[0],
      activateNow,
      addons: addonIds || null,
      email: paymentData.email,
      coupon: coupon?.name || '',
      currency: resolveCurrency(),
      returnPath: paypalReturnPath,
    }

    paypalMutation.exec(payload).then(() => {
      const token = paypalMutation.data?.token
      if (token) {
        window.location.href = `${PAYPAL_URL}${token}`
      } else {
        loadingState[1](false)
        Notifications.error(paypalMutation.error(), { timing: 8000 })
      }
    })
  }

  // Handle PayPal final flow
  useEffect(() => {
    if (type !== 'PAYPAL' || !Router.qs.paypal || !loadingState[0]) {
      return
    }

    const parts: string[] = (Router.qs.paypal as string).split('|')
    if (parts.at(1)?.length && !coupon) {
      return undefined
    } else if (parts.at(-1) === 'completed') {
      const token = Router.qs.token as string
      parts.splice(-1, 1, 'processing')
      Router.updateHistory(`${location.pathname}?paypal=${parts.join('|')}`)
      submitPayPal(token, parts.at(1))
    } else if (parts.at(-1) !== 'processing') {
      loadingState[1](false)
      Notifications.warning('The operation on PayPal was canceled.')
    }
  }, [coupon])

  // Properly triggers the logic of the digital payment method
  const triggerDigitalPayment = (handler: any, method: string) => {
    if (method === 'google-pay') {
      document.body.querySelector('div#google-pay-button button')?.dispatchEvent(new Event('click'))
    }

    confirmationModal.current?.showModal()
    setConfirmationCancel(() => () => {
      // TODO: Fix this when we have a proper way to cancel the payment
      window.location.reload()
      // (handler.gatewayHandler.callbackHandler || handler.callbackHandler).reject('Operation has been canceled.')
      // confirmationModal.current?.close()
    })
  }

  // handle a reset of Device Pay
  const resetDevicePay = (method: string) => {
    const oldButton = document.getElementById(`${method}-button`)
    const newButton = document.createElement('div')

    newButton.setAttribute('id', `${method}-button`)
    if (method === 'google-pay') {
      newButton.setAttribute('hidden', '')
    } else {
      newButton.setAttribute('class', 'ui fluid button')
    }

    if (oldButton) {
      oldButton.insertAdjacentElement('afterend', newButton)
      oldButton.remove()
    } else {
      document.body.appendChild(newButton)
    }
  }

  // Setup Google Pay
  useEffect((): any => {
    if (type === 'GOOGLE_PAY') {
      resetDevicePay('google-pay')
      return () => document.body.querySelector('div#google-pay-button')?.remove()
    }
  }, [])

  // Handle ApplePay and GooglePay through ChargeBee
  const handleDevicePay = () => {
    if (loadingState[0]) {
      return
    }

    loadingState[1](true)
    let method = type.toLowerCase()
    requestPaymentIntent(method).then(async paymentIntent => {
      if (paymentIntent) {
        method = method.replace('_', '-')
        const handler = await Chargebee.getInstance().load(method)

        handler.setPaymentIntent(paymentIntent)
        await handler.mountPaymentButton(`#${method}-button`)

        triggerDigitalPayment(handler, method)
        await handler.handlePayment()

        submitWithToken({ token: (paymentIntent.id as string) }, () => resetDevicePay(method))
      }
    }).catch(error => {
      console.error(error)
      resetDevicePay(method)
      Notifications.error('Unable to proceed with the selected payment method.')
      loadingState[1](false)
    })
  }

  const payMethodReady = paymentData.validEmail
  const cardReady = [
    payMethodReady,
    paymentData.zip?.length,
    paymentData.firstName?.length,
    paymentData.lastName?.length,
  ].every(Boolean)

  if (type === 'CARD') {
    return (
      <Button
        disabled={!cardReady || disabled}
        onClick={handleSubmit}
        content={t(`checkout.sections.submit.${activateNow ? 'continue' : 'start_trial'}`)}
        color="red"
        size="huge"
        primary
        fluid
        loading={loadingState[0]}
      />
    )
  }

  const extra = type === 'APPLE_PAY' && (
    <Portal open={true}>
      <dialog id="apple-confirmation" ref={confirmationModal}>
        <Table id="breakdown" basic="very" className="borderless" unstackable compact>
          <Table.Body>
            {!activateNow && (
              <Table.Row>
                <Table.Cell>
                  {t('checkout.sections.submit.total')}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <div className="text">{toMoney(price)}</div>
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell className="text text--large">
                {t('checkout.sections.summary.total_today')}
              </Table.Cell>
              <Table.Cell textAlign="right">
                <div className="text text--x-large weight-bold">
                  {activateNow ? toMoney(price) : toMoney(0)}
                </div>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <div id="apple-pay-button" className="ui fluid button" />
        <Button onClick={confirmationCancel} fluid>
          {t('checkout.sections.submit.cancel')}
        </Button>
      </dialog>
    </Portal>
  )

  const Component = {
    PAYPAL: PaypalBtn,
    APPLE_PAY: AppleBtn,
    GOOGLE_PAY: GoogleBtn,
  }[type]

  return (
    <>
      {extra}
      <Component
        disabled={!payMethodReady || disabled}
        onClick={type === 'PAYPAL' ? handlePayPal : handleDevicePay}
        loading={loadingState[0]}
      />
    </>
  )
}

const oSubmit = observer(Submit)
export { oSubmit as Submit }
