import React, { FC, useEffect } from 'react'
import { Header, Loader } from 'semantic-ui-react'
import { gql, useQuery } from '@apollo/client'
import { SimpleLayout } from '../components/SimpleLayout'
import { SimpleBox } from '../components/SimpleBox'
import { Dashboard } from '../components/Antivirus/Dashboard'
import { Upgrade } from '../components/Antivirus/Upgrade'
import { useNavigation } from '../hooks/useNavigation'
import { App } from '../shared/types'

type FetchData = { account: { services: string[], usage: { antivirusLicenses?: { limit: number } } }, apps: App[] }
const FETCH_QUERY = gql`
  {
    account { services usage { antivirusLicenses { limit } } }
    apps { id key name notes downloadUrl }
  }
`

const Antivirus: FC = () => {
  const { setNav, resetNav } = useNavigation()
  const { data, loading } = useQuery<FetchData>(FETCH_QUERY, { fetchPolicy: 'no-cache' })
  const hasAccess = data?.account?.services.includes('ANTIVIRUS')

  useEffect(() => {
    setNav('hidden')
    return resetNav
  }, [setNav, resetNav])

  return (
    <SimpleLayout>
      <SimpleBox className="antivirus">
        <Header as="h4">Antivirus</Header>
        {loading && (
          <Loader active />
        ) || hasAccess && (
          <Dashboard data={data!.apps} devices={data?.account?.usage?.antivirusLicenses?.limit || 0}/>
        ) || (
          <Upgrade />
        )}
      </SimpleBox>
    </SimpleLayout>
  )
}

export default Antivirus
