/// <reference types="@types/webpack-env" />

import Loader, { GQLClient } from '../config'
import { ApolloProvider } from '@apollo/client'
import { Container, Loader as SemanticLoader } from 'semantic-ui-react'
import React, { Suspense } from 'react'
import { render } from 'react-dom'
import '~assets/stylesheets/application.scss'
import Router from '../shared/router'
import { NavigationProvider, PaymentProvider } from '../contexts'
import Notifications from '../shared/notifications'
import AffiliateWatcher from '../shared/affiliate'
import { CookiesProvider } from 'react-cookie'

Router.addContext(require.context('#root/pages', true, /^((?!\/-).)*\.tsx$/))

document.addEventListener('DOMContentLoaded', () => {
  const rootElement = document.body

  Loader(() => {
    render(
      <ApolloProvider client={GQLClient}>
        <PaymentProvider>
          <CookiesProvider>
            <Notifications.View />
            <NavigationProvider>
              {/* <Layout main={mainElement}> */}
              <AffiliateWatcher>
                <Suspense fallback={(
                  <Container>
                    <SemanticLoader active />
                  </Container>
                )}>
                  <Router.Page />
                </Suspense>
              </AffiliateWatcher>
              {/* </Layout> */}
            </NavigationProvider>
          </CookiesProvider>
        </PaymentProvider>
      </ApolloProvider>,
      rootElement
    )
  })
})
