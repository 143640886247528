import { emailRegex } from '../const'

type WithPeriod = { period: number; periodUnit: string };
export const toPlanPeriod = (item: WithPeriod): 'monthly' | 'biennially' | 'yearly' => {
  if (item.periodUnit === 'MONTH' && item.period === 1) {
    return 'monthly'
  } else if (
    (item.periodUnit === 'YEAR' && item.period === 2) ||
    (item.periodUnit === 'MONTH' && item.period >= 24)
  ) {
    return 'biennially'
  } else {
    return 'yearly'
  }
}

export const toPlanIndex = (item?: WithPeriod): number => {
  if (!item) {
    return 0
  }

  if (item.period === 1 && item.periodUnit === 'MONTH') {
    return 0
  } else if (item.period === 1 || item.period === 12) {
    return 1
  } else {
    return 2
  }
}

export function toDateString(date: Date | string) {
  const dateFormatter = Intl.DateTimeFormat('en-US', {
    dateStyle: 'long',
  } as any)
  if (date instanceof Date) {
    return dateFormatter.format(date)
  }

  return dateFormatter.format(new Date(date))
}

export const isEmailValid = (email: string | undefined): boolean => {
  if (!email) {
    return false
  }
  return emailRegex.test(email)
}

//make extract_features to extractFeatures
//make email to email
export const parseStringToCamelCase = (str: string): string => str
  .toLowerCase()
  .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase())
  .replace(/[^a-zA-Z0-9]+$/, '')
